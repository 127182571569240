import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	// const toggleButton = document.getElementById('toggleButton');
	// const hamburgerMenu = document.getElementById('hamburgerMenu');
	// const hamburgerMenuActive = document.getElementById('hamburgerMenuActive');

	

	// toggleButton.addEventListener('click', () => {
	// hamburgerMenu.classList.toggle('nvButtonActive');
	// hamburgerMenuActive.classList.toggle('nvButtonActive');
	// });



		

		



	const swiperAboutHome = new Swiper(".swiperAboutHome", {
		slidesPerView: 'auto',
		preloadImages: false,
				
		
		spaceBetween: 12,
		watchSlidesProgress: true,
		grabCursor: true,
		slideToClickedSlide:true,
		centeredSlides:true,

		
		centeredSlidesBounds:true,

		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		  },
		
				
		breakpoints: {
					
			1024: {
				slidesPerView: 'auto',
				
				spaceBetween: 16,
				centeredSlides: false,
				

			},	

				

		},

		

		navigation: {
			nextEl: '.swiperAboutHome #scrollRightBtn', 
			prevEl: '.swiperAboutHome #scrollLeftBtn', 
		},
		
	

	});

	const swiperTags = new Swiper(".swiper-tags",{
		slidesPerView: 'auto',
		spaceBetween: 8,
		preloadImages: false,
		centeredSlides:false,
	

		

		
		


	})

	// const swiperGlobal = new Swiper(".swiper-global", {
	// 	direction: 'horizontal',
	// 	loop: true,
	// 	slidesPerView: 10,
	// 	spaceBetween: 8,
	// 	speed: 1860,
	// 	effect: 'slide',
	// 	autoplay: {
	// 	  delay: 200,
	// 	},
	// });

	// const swiperGlobal = new Swiper(".swiper-global", {
	// 	direction:'horizontal',
		
	// 	slidesPerView: 9, // Mostra três slides por vez
	// 	spaceBetween: 0, // Espaço entre os slides
	// 	speed: 1260, 
   	// 	effect: 'slide', 
	// 	autoplay: {
	// 		delay: 200,
			
	// 	},
	// });
	  

	const swiperGlobal = new Swiper(".swiper-global", {
		// direction:'horizontal',
		// loop: true,
		// slidesPerView: "auto", 
		// spaceBetween: 0, 
		// speed: 4000, 
		// autoplay: {
		// 	delay: 1,
		// 	disableOnInteraction: true,
		// },

		// breakpoints: {
		// 	320: {
		// 		slidesPerView: 1
		// 	},
		// 	420: {
		// 		slidesPerView: 2
		// 	},
		// 	1024: {
		// 		slidesPerView: 3
		// 	},
		// 	1440: {
		// 		slidesPerView: 6
		// 	},
		// 	1920: {
		// 		slidesPerView: 9
		// 	}	

				

		// },


		slidesPerView: 'auto',
        loop: true,
        allowTouchMove: false,
        alowtouch: false,
        speed: 3000,
		
        
       
        autoplay: {
            delay:1,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          },
	});
	

	const swiperValores = new Swiper(".swiper-valores", {
	
		slidesPerView: 'auto',
        loop: true,
        allowTouchMove: false,
        alowtouch: false,
        speed: 3000,
		spaceBetween: 8, 
        
       
        autoplay: {
            delay:1,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          },
	});

	

	const textParagraphs = document.querySelectorAll(".text-mobile");
	const hiddenText = document.querySelector(".hiddenButtonMobile");
	const icon1 = document.querySelector(".icon1");
	const icon2 = document.querySelector(".icon2");

	const button = document.getElementById("buttonHiddenMobile");
	button.addEventListener("click", () => {
	
	textParagraphs.forEach((paragraph) => {
		paragraph.classList.toggle("text-sm");
	});

		hiddenText.classList.toggle("sm:hidden");
		icon1.classList.toggle("hidden");
		icon2.classList.toggle("hidden");
	});

	const swiperCarroselEmpresasTexto = new Swiper(".swiperCarroselEmpresasTexto", {
		slidesPerView: 1,
		spaceBetween: 32,
		loop: true,
		preloadImages: false,
		watchSlidesProgress: true,
		navigation: {
		  nextEl: '.swiperCarroselEmpresasTexto #scrollRightBtn',
		  prevEl: '.swiperCarroselEmpresasTexto #scrollLeftBtn',
		},
		thumbs: {
		  swiper: swiperRepresentantes,
		},
		pagination: {
			el: ".swiperCarroselEmpresasTexto .swiper-pagination",
			clickable: true,
		  },
		
	});

	var swiperShort = new Swiper(".swiperShort", {
		loop: true,
		spaceBetween: 10,
		slidesPerView: 3,
		freeMode: true,
		watchSlidesProgress: true,

		
		
		
	});

	var swiperRepresentantes = new Swiper(".swiperRepresentantes", {
		loop: true,
		spaceBetween: 10,
		slidesPerView: 'auto',
		slideToClickedSlide:true,
		navigation: {
			nextEl: '.swiperCarroselEmpresasTexto #scrollRightBtn',
			prevEl: '.swiperCarroselEmpresasTexto #scrollLeftBtn',
		},

		thumbs: {
		  swiper: swiperCarroselEmpresasTexto,
		  
		},

		pagination: {
			el: ".pagination-representatntes",
			clickable: true,
		},

		

		

	});

	swiperShort.controller.control = swiperRepresentantes;
    swiperRepresentantes.controller.control = swiperShort;


	





	
	










}