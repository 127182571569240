import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import contatoForm from "./modules/contato-conectese";
import mottaEquipeText from "./modules/converteditorjs";
import MenuFunc from "./modules/menu";

// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
contatoForm()
mottaEquipeText()
MenuFunc()

// Pages
PageHome()
PageLogin()?.init()

// utils logs
logs()

// document.getElementById('toggleButton').addEventListener('click', function() {
// 	var meuNav = document.getElementById('meuNav');
// 	let navMenuList = document.querySelector(".navMenuList")
// 	let ornamentoClass = document.querySelector(".ornamentoClass")
// 	if (meuNav.style.height === '43%') {
// 		meuNav.style.height = '8%';
//         meuNav.style.color = '#fff'
// 		meuNav.style.background = "#02221B33"

//         let dropbtnElements = meuNav.querySelectorAll('.dropbtn');

        
//         dropbtnElements.forEach(paragraph => paragraph.style.color = '#ffffff');

// 		navMenuList.classList.add('hidden');
// 	} else {
// 		meuNav.style.height = '43%';
// 		meuNav.style.background = "#fff"
//         meuNav.style.color = "#305a5f"
//         let dropbtnElements = meuNav.querySelectorAll('.dropbtn');

        
//         dropbtnElements.forEach(paragraph => paragraph.style.color = '#305a5f');

// 		navMenuList.classList.remove('hidden');
		
		
// 	}
    
// });


    let textPt = document.querySelectorAll(".text-pt");
    let textEn = document.querySelectorAll(".text-en");
    let textEs = document.querySelectorAll(".text-es");


document.addEventListener('DOMContentLoaded', function () {
    const text_select_pt = document.querySelector('.text_select_pt');
    const text_select_en = document.querySelector('.text_select_en');
    const text_select_es = document.querySelector('.text_select_es');
  
    
  
    text_select_pt.addEventListener("click", () => switchLanguage('pt'));
    text_select_en.addEventListener("click", () => switchLanguage('en'));
    text_select_es.addEventListener("click", () => switchLanguage('es'));
  
    // Carrega o idioma salvo (opcional)
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      switchLanguage(storedLanguage);
    }
  });
  
  function switchLanguage(lang) {
    if (lang === 'pt') {
      document.documentElement.classList.add('lang-pt');
      document.documentElement.classList.remove('lang-en', 'lang-es');
      switchText(textPt, textEn, textEs);
    } else if (lang === 'en') {
      document.documentElement.classList.add('lang-en');
      document.documentElement.classList.remove('lang-pt', 'lang-es');
      switchText(textEn, textPt, textEs);
    } else if (lang === 'es') {
      document.documentElement.classList.add('lang-es');
      document.documentElement.classList.remove('lang-pt', 'lang-en');
      switchText(textEs, textPt, textEn);
    }
    // Salva o idioma no localStorage
    localStorage.setItem('selectedLanguage', lang);
  }
  
  function switchText(showText, hideText1, hideText2) {
    showText.forEach(element => element.classList.remove('hidden'));
    hideText1.forEach(element => element.classList.add('hidden'));
    hideText2.forEach(element => element.classList.add('hidden'));
  }



